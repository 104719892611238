import Link from "next/link";
import { FC, memo } from "react";
import { css } from "styled-components";

import FeedbackButton from "~/components/FeedbackButton";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";

import { WorkspaceAvatar } from "../WorkspaceAvatar";
import MenuItem from "./MenuItem";
import {
  Container,
  Content,
  Heading,
  Logo,
  Menu,
  SettingsIcon,
  SettingsLink,
} from "./styles";
import type { Props } from "./types";
import { useNavMenuItems } from "./useNavMenuItems";

const Sidebar: FC<Props> = ({
  workspaceLogoFileId,
  workspaceName,
  workspaceSlug,
  open,
}) => {
  const { isMember, isAdmin } = useWorkspaceContext();
  return (
    <Container $open={open}>
      <Content>
        <Heading>
          <div
            css={`
              display: flex;
              width: 100%;
              gap: 0.75rem;
            `}
          >
            {workspaceLogoFileId ? (
              <WorkspaceAvatar
                size="sm"
                name={workspaceName}
                logoFileId={workspaceLogoFileId}
              />
            ) : (
              <Logo
                css={css`
                  width: 1.5rem;
                  height: 1.5rem;
                `}
              />
            )}
            <span>{workspaceName}</span>
          </div>
          {(isMember || isAdmin) && (
            <Link href={`/${workspaceSlug}/settings`}>
              <SettingsLink href={`/${workspaceSlug}/settings`}>
                <SettingsIcon />
              </SettingsLink>
            </Link>
          )}
        </Heading>
        <MenuContent />
      </Content>
      <FeedbackButton
        css={{
          justifySelf: "flex-end",
          marginBlockStart: "auto",
        }}
      />
    </Container>
  );
};

export const MenuContent = () => {
  const menuItems = useNavMenuItems();
  return (
    <>
      <Menu>
        {menuItems.map((props, idx) => (
          <MenuItem key={idx} {...props} />
        ))}
      </Menu>
    </>
  );
};

export default memo(Sidebar);
