import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import styled from "styled-components";

import DefaultCaretIcon from "~/assets/icons/caret.svg";
import DefaultNavLink from "~/components/NavLink";

export const MenuHeadingLink = styled(DefaultNavLink)`
  align-items: center;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.moon};
  font-weight: ${({ theme }) => theme.weights.medium};

  display: flex;
  min-width: 100%;
  margin-top: 0.25rem;
  padding: 0.75rem 0.5rem;
  text-decoration: none;

  &.active,
  &.active:hover,
  &.active:focus {
    background-color: ${({ theme }) => theme.colors.speech75};
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.speech25};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const NavLink = styled(DefaultNavLink)`
  align-items: center;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.moon};
  font-weight: ${({ theme }) => theme.weights.medium};

  display: flex;
  min-width: 100%;
  margin-top: 0.25rem;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  text-decoration: none;

  &.active,
  &.active:hover,
  &.active:focus {
    background-color: ${({ theme }) => theme.colors.speech75};
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.speech25};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-left: 0.78125rem;
  width: 100%;
  font-weight: ${({ theme }) => theme.weights.medium};
`;

export const SubMenuLabel = styled.div`
  font-size: 14px;
  line-height: 15.4px;
  margin-left: 1.78125rem;
  width: 100%;
  font-weight: ${({ theme }) => theme.weights.regular};
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    border: none;
    background: none;
    box-shadow: none;
    color: inherit;
  }
`;
export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
  }
  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const DisclosureButton = styled.button`
  margin-top: -3px; /* visual centering */
  cursor: pointer;
  color: inherit;
  background: none;
  border: none;
  padding: 0 0.75rem;
  margin-right: 0.25rem;
`;

export const CaretIcon = styled(DefaultCaretIcon)`
  width: 1.5rem;
  height: 1.5rem;
  @media (prefers-reduced-motion: no-preference) {
    transition: transform 0.2s ease-in-out;
  }
`;
