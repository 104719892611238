import Link from "next/link";
import { FC, memo, PropsWithChildren } from "react";

import Header from "~/components/Header";
import { useGlobalLoadingIndicator } from "~/contexts/GlobalLoadingIndicatorContext";
import useBestRootPath from "~/hooks/useBestRootPath";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

import {
  AutoSaveIcon,
  Breadcrumb,
  BreadcrumbLink,
  Breadcrumbs,
  BreadcrumbsList,
  CloudCheckIcon,
  CloudSyncIcon,
  Container,
  DraftHeaderContainer,
  Logo,
} from "./styles";
import type { FocusHeaderProps } from "./types";

const FocusHeader: FC<PropsWithChildren<FocusHeaderProps>> = ({
  actionButton,
  actionItems,
  breadcrumbs,
  children,
  editablePage = false,
  isSubmitting = false,
  visibility,
  shareButton,
  ...rest
}) => {
  const workspaceSlug = useWorkspaceSlug();
  const homeLink = useBestRootPath({ workspaceSlug });

  const { loading } = useGlobalLoadingIndicator();

  return (
    <>
      <Container {...rest}>
        <DraftHeaderContainer>
          <Link href={homeLink}>
            {/* Added a dummy href to the <a> below to satisfy a11y linter (03/21).
          Soon, this won't be necessary anymore because Next is working on supporting a Link that doesn't require an <a> child.
          More info: https://github.com/vercel/next.js/discussions/8207
          */}
            <a href={homeLink}>
              <Logo />
            </a>
          </Link>
          <Breadcrumbs>
            <BreadcrumbsList>
              {breadcrumbs.map(({ label, href }, idx) => {
                const isTitleBreadcrumb =
                  breadcrumbs.length > 1 && idx === breadcrumbs.length - 1;

                return (
                  <Breadcrumb key={idx}>
                    {isTitleBreadcrumb ? (
                      <>{label}</>
                    ) : (
                      <Link href={href} passHref>
                        <BreadcrumbLink>{label}</BreadcrumbLink>
                      </Link>
                    )}
                  </Breadcrumb>
                );
              })}
            </BreadcrumbsList>
          </Breadcrumbs>
          {editablePage && (
            <AutoSaveIcon>
              {isSubmitting || loading ? <CloudSyncIcon /> : <CloudCheckIcon />}
            </AutoSaveIcon>
          )}
        </DraftHeaderContainer>
        {children}
        <Header
          actionButton={actionButton}
          actionItems={actionItems}
          workspaceSlug={workspaceSlug}
          shareButton={shareButton}
          visibility={visibility}
        />
      </Container>
    </>
  );
};

export default memo(FocusHeader);
