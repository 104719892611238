import styled, { css } from "styled-components";

export const CountLabel = styled.span<{
  variant: "urgent" | "lightOnDark" | "lightOnLight" | "speech";
}>`
  border-radius: 1rem;
  ${({ theme, variant }) =>
    ({
      urgent: css`
        background-color: ${theme.colors.sourWatermelon};
        color: ${theme.colors.white};
      `,
      lightOnDark: css`
        background-color: ${theme.colors.moonLight}26;
        color: ${theme.colors.white};
      `,
      lightOnLight: css`
        background-color: ${theme.colors.moonMedium};
        color: ${theme.colors.moonDark};
      `,
      speech: css`
        background-color: ${theme.colors.speech};
        color: ${theme.colors.white};
      `,
    }[variant])};
  padding: 0rem 0.5rem;
  min-width: 1.35rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.3125rem;
`;
