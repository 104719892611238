import Link from "next/link";

import LoginPrompt from "~/containers/Common/LoginPrompt";
import { useUserContext } from "~/contexts/UserContext";

const WorkspaceUndefined = ({}) => {
  const { user } = useUserContext();

  return (
    <>
      <ul>
        {user._id ? (
          <>
            <div>TODO: this should be an unreachable state.</div>
            <h1>Available Workspaces</h1>
            {user.workspaces ? (
              <ul>
                {user.workspaces.map(({ name, _id }) => (
                  <li key={_id}>
                    {name} <Link href={`/${_id}`}>Go to workspace</Link>
                  </li>
                ))}
              </ul>
            ) : (
              <div>
                No available workspaces, <em>REALLY</em> contact support (Chase
                😅)
              </div>
            )}
            <div>Let support know you got here (support@murmur.com)</div>
          </>
        ) : (
          <LoginPrompt />
        )}
      </ul>
    </>
  );
};

export default WorkspaceUndefined;
