import styled, { css } from "styled-components";

import { TShirtSize, TShirtSizes } from "~/utils/TShirtSizes";
import { workspaceLogoFileURL } from "~/utils/workspace";

import { Avatar } from "../Avatar";

const LogoImage = styled.img<{ size: TShirtSize; border?: string }>`
  ${({ size }) => css`
    height: ${TShirtSizes[size].height}px;
    width: ${TShirtSizes[size].width}px;
  `}
  font-size: 0;
  border-radius: 50%;
  border: ${({ border }) => border || "none"};
`;

export const WorkspaceAvatar = ({
  logoFileId,
  name,
  size,
  border,
}: {
  logoFileId?: string;
  name: string;
  size: TShirtSize;
  border?: string;
}) =>
  logoFileId ? (
    <LogoImage
      size={size}
      src={workspaceLogoFileURL(logoFileId)}
      alt={`${name} Logo`}
      border={border}
    />
  ) : (
    <Avatar
      size={size}
      alt={name}
      src={""}
      css={css`
        border: ${border || "none"};
      `}
    />
  );
