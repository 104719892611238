import type { TypedDocumentNode } from "@apollo/client";
import { gql } from "@apollo/client";

import type {
  GetAgreementTypesCountsQuery,
  GetAgreementTypesCountsQueryVariables,
} from "~/graphql/generated";

export const GET_AGREEMENT_TYPES_COUNTS: TypedDocumentNode<
  GetAgreementTypesCountsQuery,
  GetAgreementTypesCountsQueryVariables
> = gql`
  query GetAgreementTypesCounts($agreementsParams: AgreementsParams!) {
    draftsCount(params: $agreementsParams) {
      total
    }
    activeAgreementsCount(params: $agreementsParams) {
      total
      needsAction
    }
    proposalsCount(params: $agreementsParams) {
      total
      needsAction
    }
    templatesCount {
      total
    }
    allCollectionsCount {
      total
    }
  }
`;
