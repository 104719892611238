import Link from "next/link";
import React from "react";
import type { FC } from "react";

const LoginPrompt: FC = () => (
  <div
    css={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    }}
  >
    <div>
      <h1 css={{ marginTop: 0 }}>Log in required</h1>
      <span>
        You need to <Link href="/login">log in</Link> to access this page.
      </span>
    </div>
  </div>
);

export default LoginPrompt;
