import { useRouter } from "next/router";
import React, { forwardRef } from "react";

import type { Props } from "./types";

const NavLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className, children, href, ...props }, ref) => {
    const { asPath: pathname } = useRouter();

    return (
      <a
        className={`${className ?? ""}${pathname === href ? " active" : ""}`}
        href={href}
        ref={ref}
        {...props}
      >
        {children}
      </a>
    );
  }
);

export default NavLink;
