import styled from "styled-components";

import DefaultBoltIcon from "~/assets/icons/bolt.svg";
import DefaultSearchIcon from "~/assets/icons/search.svg";
import DefaultButton from "~/components/Button";
import DefaultNotificationIcon from "~/components/NotificationsIcon";
import { resetListStyles } from "~/styles/resets";

export const Container = styled.ul<{ $fullWidth?: boolean }>`
  ${resetListStyles};
  display: flex;
  align-items: center;
  position: relative;
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}
`;

export const Item = styled.li`
  min-height: 24px;
`;

export const SearchIcon = styled(DefaultSearchIcon)`
  color: ${({ theme }) => theme.colors.gravel};
`;

export const LoginLink = styled(DefaultButton).attrs({ forwardedAs: "a" })`
  margin-top: 0;
`;

const Button = styled(DefaultButton).attrs({ variant: "icon" })`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.gravel};

  &:hover {
    background-color: ${({ theme }) => theme.colors.moon};
  }
`;

export const SearchButton = styled(Button)``;
export const NotificationsButton = styled(Button)``;
export const ChangelogButton = styled(Button)`
  .group-layout & {
    color: ${({ theme }) => theme.colors.oil};
  }
`;

export const LightChangelogButton = styled(Button)`
  &:not(:hover) {
    color: ${({ theme }) => theme.colors.speechLight};
  }
`;

export const NotificationIcon = styled(DefaultNotificationIcon)`
  color: ${({ theme }) => theme.colors.gravel};
`;

export const ChangelogIcon = styled(DefaultBoltIcon)`
  width: 24px;
  height: 24px;
`;

export const AccountOptionsList = styled.ul`
  ${resetListStyles};
  width: 12rem;
  position: absolute;
  right: 0;
  box-shadow: 0px 0px 10px ${({ theme }) => theme.colors.moon};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  z-index: 10;
`;

export const AccountOptionsListItem = styled.li`
  > a,
  > button {
    background-color: transparent;
    cursor: pointer;
    border: none;
    display: block;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.oil};
  }

  &:not(:last-child):after {
    display: block;
    width: 90%;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    content: "";
    border-bottom: 1px solid ${({ theme }) => theme.colors.moon};
  }
`;
