import useMediaQuery from "@mui/material/useMediaQuery";
import { compact } from "lodash";

import { Breakpoint, breakpoints } from "~/styles/global";

/**
 * useResponsiveMatch will return true if the viewport's
 * width is above and/or below a certain breakpoint.
 */
export const useResponsiveMatch = ({
  above,
  below,
}: {
  above?: Breakpoint;
  below?: Breakpoint;
}) => {
  const min = above ? `(min-width: ${breakpoints[above].min})` : ``;
  const max = below ? `(max-width: ${breakpoints[below].max})` : ``;
  return useMediaQuery(compact([min, max]).join(" and "), { noSsr: true });
};
