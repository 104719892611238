import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import { Group as GroupFromServer } from "~/graphql/generated";
import { GET_USER_WORKSPACE_GROUPS } from "~/graphql/queries/group/getUserWorkspaceGroups";
import useQueryMachine from "~/hooks/useQueryMachine";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";
import { Group, normalizeGroup } from "~/model/group";

export const useUserWorkspaceGroups = () => {
  const workspaceId = useWorkspaceSlug();
  const { isGuest } = useWorkspaceContext();
  const result = useQueryMachine<{
    userWorkspaceGroups: Array<GroupFromServer>;
  }>(GET_USER_WORKSPACE_GROUPS, {
    nextFetchPolicy: "cache-first",
    variables: {
      params: { workspaceId },
    },
    skip: !workspaceId || isGuest,
  });
  if (result.state === "SUCCESS" || result.state === "LOADING") {
    const normalized: Array<Group> =
      result.context?.userWorkspaceGroups?.map(normalizeGroup) ?? [];

    return { state: result.state, context: normalized };
  }

  return result;
};
