import styled from "styled-components";

export const MainWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.moonLight};
`;

export const AgreementFormWrap = styled.div<{ isEmbedded?: boolean }>`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ isEmbedded = false }) => (isEmbedded ? "100%" : "85rem")};
`;
