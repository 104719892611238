import { AgreementType, Round } from "~/graphql/generated";
import type { themes } from "~/styles/themes";

type Colors = typeof themes["light"]["colors"];
type ColorName = keyof Colors;
type Colorway = { primary: ColorName; secondary: ColorName; muted: ColorName };

export const AgreementTypeColors: { [type in AgreementType]: ColorName } = {
  [AgreementType.Norm]: "malibu",
  [AgreementType.Foundational]: "murpleLight",
  [AgreementType.Strategy]: "murpleExtraLight",
  [AgreementType.Process]: "periwinkle",
  [AgreementType.Policy]: "periwinkleLight",
  [AgreementType.Norm]: "malibu",
  [AgreementType.Meeting]: "malibuLight",
  [AgreementType.Decision]: "sunshine",
  [AgreementType.Measure]: "sunshineLight",
  [AgreementType.Role]: "sorbet",
  [AgreementType.Charter]: "sorbetLight",
  [AgreementType.Uncategorized]: "moon",
};

export const AgreementRoundColorways: { [type in Round]: Colorway } = {
  [Round.Understand]: {
    primary: "malibu",
    secondary: "malibuLight",
    muted: "malibuPorcelain",
  },
  [Round.Improve]: {
    primary: "sunshine",
    secondary: "sunshineLight",
    muted: "sunshinePorcelain",
  },
  [Round.Finalize]: {
    primary: "sorbet",
    secondary: "sorbetLight",
    muted: "sorbetPorcelain",
  },
};
