import Link from "next/link";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useCallback, useState } from "react";
import { css } from "styled-components";

import InviteToWorkspaceModal from "~/browser/workspace-settings/components/InviteToWorkspaceModal";
import ActionMenu from "~/components/ActionMenu";
import { AvatarUser, UserAvatar } from "~/components/Avatar";
import Button from "~/components/Button";
import ClickAwayListener from "~/components/ClickAwayListener";
import CreateAgreementLink from "~/components/CreateAgreementLink";
import Logo from "~/components/Logo";
import Spacer from "~/components/Spacer";
import Tooltip from "~/components/Tooltip";
import { useUserContext } from "~/contexts/UserContext";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import useBestRootPath from "~/hooks/useBestRootPath";

import { ResponsiveHide } from "../Hidden/ResponsiveHide";
import {
  AccountOptionsList,
  AccountOptionsListItem,
  ChangelogButton,
  ChangelogIcon,
  Container,
  Item,
  LightChangelogButton,
  LoginLink,
} from "./styles";
import type { AccountOptionsProps, HeaderProps } from "./types";

export const AccountOptions: FC<AccountOptionsProps> = ({
  onChangeOpen,
  open,
  accountHref,
}) => {
  const { user } = useUserContext();

  const { authenticated, logout } = useUserContext();

  const onClose = useCallback(() => {
    onChangeOpen(false);
  }, [onChangeOpen]);

  const onToggleOpen = useCallback(() => {
    onChangeOpen((prevOpen) => !prevOpen);
  }, [onChangeOpen]);

  const hasInvitations = Boolean(
    user.workspaceInvitations?.length || user.proposalInvitations?.length
  );
  const hasOtherWorkspaces =
    (user.workspaces?.length ?? 0) + (user.guestOfWorkspaces?.length ?? 0) > 1;

  return (
    <>
      {authenticated ? (
        <ClickAwayListener onClickAway={onClose}>
          <Button variant="icon" onClick={() => onToggleOpen()}>
            <UserAvatar user={user as AvatarUser} />
          </Button>
          {open && (
            // TODO: Replace this list with ActionMenu component
            <AccountOptionsList>
              <AccountOptionsListItem>
                <Link href={accountHref}>
                  {/* Added a dummy href to the <a> below to satisfy a11y linter (03/21).
                  Soon, this won't be necessary anymore because Next is working on supporting a Link that doesn't require an <a> child.
                  More info: https://github.com/vercel/next.js/discussions/8207
                  */}
                  <a href={accountHref}>Account Settings</a>
                </Link>
              </AccountOptionsListItem>
              {hasInvitations && (
                <AccountOptionsListItem>
                  <Link href={`/welcome/accept-invite`}>
                    <a href={`/welcome/accept-invite`}>View Invitations</a>
                  </Link>
                </AccountOptionsListItem>
              )}
              {hasOtherWorkspaces && (
                <AccountOptionsListItem>
                  <Link href={`/welcome/switch-workspaces`}>
                    <a href={`/welcome/switch-workspaces`}>Switch Workspace</a>
                  </Link>
                </AccountOptionsListItem>
              )}
              <AccountOptionsListItem>
                <a href={"/api/logout"} onClick={() => logout()}>
                  Logout
                </a>
              </AccountOptionsListItem>
            </AccountOptionsList>
          )}
        </ClickAwayListener>
      ) : (
        <LoginLink variant="speech" href="/login">
          Login
        </LoginLink>
      )}
    </>
  );
};

const Header: FC<HeaderProps> = ({
  actionButton: ActionButton = CreateAgreementLink,
  actionItems = [],
  visibility: Visibility,
  shareButton,
  workspaceSlug,
  showLogo = false,
}) => {
  const { pathname } = useRouter();
  const { isAdmin } = useWorkspaceContext();

  const shouldDisplayInviteLink = pathname === "/[workspace]" && isAdmin;

  const [accountOptionsOpen, setAccountOptionsOpen] = useState(false);

  const accountHref = useBestRootPath({
    workspaceSlug,
    appendPath: "/account",
  });

  return (
    <Container $fullWidth={!!showLogo}>
      <ResponsiveHide below="sm">
        <div
          css={css`
            display: flex;
            flex: 1;
            justify-content: space-between;
            width: 100%;
          `}
        >
          <div> {showLogo && <Logo />}</div>
          <div css={{ display: "flex" }}>
            {Visibility && (
              <Spacer right={1}>
                <Visibility />
              </Spacer>
            )}
            {shareButton && <Spacer right={1}>{shareButton}</Spacer>}
            {shouldDisplayInviteLink && (
              <Spacer right={1}>
                <InviteToWorkspaceModal
                  buttonVariant="hollow-primary-on-light"
                  buttonLabel="Invite People"
                />
              </Spacer>
            )}
            <ActionButton />
            {actionItems.length ? (
              <div
                css={css`
                  margin-right: 1rem;
                  display: flex;
                  align-items: center;
                `}
              >
                <Item>
                  <ActionMenu items={actionItems} zIndex={4000} />
                </Item>
              </div>
            ) : null}
            <Item>
              <Spacer right={1}>
                <CannyChangelogButton />
              </Spacer>
            </Item>
            {/* <Item>
        <SearchButton>
          <SearchIcon />
        </SearchButton>
      </Item>
      <Item>
        <NotificationsButton>
          <NotificationIcon unread={false} />
        </NotificationsButton>
      </Item> */}
            <Item>
              <AccountOptions
                accountHref={accountHref}
                open={accountOptionsOpen}
                onChangeOpen={setAccountOptionsOpen}
              />
            </Item>
          </div>
        </div>
      </ResponsiveHide>
      <ResponsiveHide above="sm">
        <div
          css={`
            display: flex;
            gap: 1rem;
            align-items: center;
          `}
        >
          {showLogo && <Logo />}
          <CannyChangelogButton variant="light" />
          <AccountOptions
            accountHref={accountHref}
            open={accountOptionsOpen}
            onChangeOpen={setAccountOptionsOpen}
          />
        </div>
      </ResponsiveHide>
    </Container>
  );
};

export function CannyChangelogButton({
  variant = "dark",
}: {
  variant?: "dark" | "light";
}) {
  // Canny is loaded as a part of the CannyContainer
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore 3rd party implementation
  if (process.browser && window?.Canny) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 3rd party implementation
    window.Canny("initChangelog", {
      appID: "607110cc4c231e2b2f389b8d",
      position: "bottom",
      align: "right",
    });
  }

  return (
    <Tooltip zIndex={3100} title="New in Murmur">
      {variant == "dark" ? (
        <ChangelogButton data-canny-changelog>
          <ChangelogIcon />
        </ChangelogButton>
      ) : (
        <LightChangelogButton data-canny-changelog>
          <ChangelogIcon />
        </LightChangelogButton>
      )}
    </Tooltip>
  );
}

export default Header;
