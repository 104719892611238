import type { ReactNode } from "react";

import { useIsEmbedded } from "~/hooks/useIsEmbedded";
import { breakpoints } from "~/styles/global";

type Props = {
  children: ReactNode;
};

export default function FocusBody({ children }: Props) {
  const isEmbedded = useIsEmbedded();
  return (
    <div
      css={
        isEmbedded
          ? ``
          : `
        padding-top: 5rem;
        padding-right: 1rem;
        padding-bottom: 7rem;
        padding-left: 1rem;

        @media (min-width: ${breakpoints.sm.min}) {
          padding-top: 2rem;
          padding-right: 7rem;
          padding-bottom: 7rem;
          padding-left: 7rem;
        }
      `
      }
    >
      {children}
    </div>
  );
}
