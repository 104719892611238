import { Tooltip as MaterialTooltip, TooltipProps } from "@mui/material";
import type { ReactNode } from "react";
import styled from "styled-components";

export type Props = TooltipProps & {
  className?: string;
  enterDelay?: number;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  title: ReactNode;
  /**
   * use zIndex to override the default MUI zIndex for the tooltip 1500).
   */
  zIndex?: number;
};

/**
 * The Material UI Tooltip is a special component that uses React Portals in
 * order to work. This makes styling it challenging. So challenging, they
 * devoted a specific example for it here:
 * https://next.material-ui.com/guides/interoperability/#portals
 *
 * This `AddClasses` component is simply a go between that applies the
 * props.className to the popper. This makes it so the "tooltip" class is
 * scoped correctly.
 *
 * Adding this inbetween component also has the benefit of preserving the
 * CSS syntax highlighting in my editor, which is a trade off I can live with
 * (a few extra lines for the right highlighting).
 */
const AddCustomClasses = (props: Props) => (
  <MaterialTooltip
    classes={{ popper: props.className, tooltip: "tooltip" }}
    {...props}
  />
);

export const Tooltip = styled(AddCustomClasses)`
  /* z-index of the tooltip, overriding the default of 1500  */
  &.MuiTooltip-popper {
    ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  }

  & .tooltip {
    background-color: ${({ theme }) => theme.colors.oil};
    font-family: ${({ theme }) => theme.fontFamily.graphik};
    font-size: 0.75rem;
    padding: ${({ title }) => (!title ? "0" : "0.5rem 0.75rem")};
    border-radius: 0.25rem;
  }
`;

export default Tooltip;

export const LightTooltip = styled(Tooltip)`
  .tooltip {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0.1875rem 0.25rem #00000034;
    padding: 1.25rem;
    border-radius: 0.625rem;

    p {
      margin: 0 0 1rem 0;
      color: ${({ theme }) => theme.colors.oil};
      font-size: 0.9rem;
      font-weight: ${({ theme }) => theme.weights.regular};
      line-height: 1.5;
    }

    a {
      color: ${({ theme }) => theme.colors.speech};
    }
  }
`;

export const MarigoldTooltip = styled(LightTooltip)`
  .tooltip {
    background-color: ${({ theme }) => theme.colors.marigoldYellowLight};
  }
`;

export const MalibuTooltip = styled(LightTooltip)`
  .tooltip {
    background-color: ${({ theme }) => theme.colors.malibuLight};
  }
`;

export const SorbetTooltip = styled(LightTooltip)`
  .tooltip {
    background-color: ${({ theme }) => theme.colors.sorbetLight};
  }
`;
