import type { FC } from "react";

import Button from "../Button";
import Emoji from "../Emoji";

const FeedbackButton: FC = ({ ...rest }) => {
  return (
    <Button
      forwardedAs="a"
      href="https://plumb.notion.site/01f144ac0c2844c3b1ca05274e63a883?v=bf1d2b021ee74f899cf18329c902a0c2&pvs=4"
      target="_blank"
      rel="noreferrer"
      css={({ theme }) => ({
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        fontWeight: 500,
        backgroundColor: "transparent",
        borderColor: theme.colors.speech,
        borderWidth: "1px",
        borderStyle: "solid",
        paddingTop: "0.75rem",
        paddingRight: "1rem",
        paddingBottom: "0.75rem",
        paddingLeft: "1rem",
        fontSize: "14px",
      })}
      {...rest}
    >
      <Emoji
        css={{ marginRight: ".5rem" }}
        label="Share feedback"
        symbol="🗳️"
        size={18}
      />{" "}
      Help Center
    </Button>
  );
};

export default FeedbackButton;
