import CircularProgress from "@mui/material/CircularProgress";
import Link from "next/link";
import React, { FC, useCallback, useState } from "react";

import ChevronIcon from "~/assets/icons/chevron-small.svg";
import { CountLabel } from "~/components/CountLabel";
import { NavItem } from "~/components/Sidebar/useNavMenuItems";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DisclosureButton,
  Label,
  MenuHeadingLink,
  NavLink,
} from "./styles";

export type Props = NavItem;

const MenuItem: FC<Props> = (props) => (
  <>
    {props.submenuItems?.length ? (
      <MenuItemWithChildren {...props} />
    ) : (
      <LeafMenuItem {...props} />
    )}
  </>
);

const LeafMenuItem: FC<Props> = ({
  href,
  icon,
  label,
  count,
  countIsUrgent,
}) => {
  const item = (
    <NavLink>
      {icon}
      <Label>{label}</Label>
      {count ? (
        <CountLabel variant={countIsUrgent ? "urgent" : "lightOnDark"}>
          {" "}
          {count}{" "}
        </CountLabel>
      ) : null}
    </NavLink>
  );
  return (
    <>
      {href ? (
        <Link key={href} href={href} passHref>
          {item}
        </Link>
      ) : (
        item
      )}
    </>
  );
};

const MenuItemWithChildren: FC<Props> = ({
  submenuItems,
  href,
  icon,
  label,
  isLoading = false,
}) => {
  // TODO: store expanded state in session storage?
  const [isExpanded, setIsExpanded] = useState(true);
  const handleDisclosureTriangleClick = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setIsExpanded((prev) => !prev);
  }, []);

  const item = (
    <MenuHeadingLink>
      {isLoading ? (
        <CircularProgress color="inherit" size="1rem" />
      ) : (
        <DisclosureButton onClick={handleDisclosureTriangleClick}>
          <ChevronIcon
            css={css`
              padding: 0;
              transform: ${isExpanded ? "rotate(0)" : "rotate(-90deg)"};
            `}
          />
        </DisclosureButton>
      )}
      {icon}
      <Label>{label}</Label>
    </MenuHeadingLink>
  );

  return (
    <Accordion disableGutters expanded={isExpanded}>
      <AccordionSummary>
        {href ? (
          <Link key={href} href={href} passHref>
            {item}
          </Link>
        ) : (
          item
        )}
      </AccordionSummary>

      <AccordionDetails>
        {submenuItems?.map((item) => (
          <LeafMenuItem key={item.href} {...item} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuItem;
