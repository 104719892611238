import React, { FC, memo } from "react";

import { UnreadIndicator } from "./styles";
import type { Props } from "./types";

const Icon: FC<Props> = ({ unread, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="none"
    viewBox="0 0 28 28"
    {...rest}
  >
    {unread && <UnreadIndicator d="M20 4a4 4 0 118 0 4 4 0 01-8 0z" />}
    <path
      clipRule="evenodd"
      d="M18 15v5l2 2v1H4v-1l2-2v-5c0-3.08 1.63-5.64 4.5-6.32V8c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v.68c2.86.68 4.5 3.25 4.5 6.32zm-4 9a2 2 0 11-4 0h4z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(Icon);
