import { useRouter } from "next/router";
import { useEffect } from "react";

import UserAvatar from "~/components/Avatar";
import { useAgreementTypesCounts } from "~/components/Sidebar/graphql/hook";
import { WorkspaceAvatar } from "~/components/WorkspaceAvatar";
import { useUserContext } from "~/contexts/UserContext";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import { useUserWorkspaceGroups } from "~/graphql/hooks/group/useUserWorkspaceGroups";
import { useResponsiveMatch } from "~/hooks/useResponsiveMatch";
import useSessionStorage from "~/hooks/useSessionStorage";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";
import { getSimpleEditorTextOrLegacyText } from "~/utils/getTextFromRootNode";

import Emoji from "../Emoji";
import {
  CompassIcon,
  GroupsIcon,
  HomeIcon,
  PersonIcon,
  SettingsIcon,
} from "./styles";

export type NavItem = {
  label: JSX.Element | string;
  href?: string;
  icon?: JSX.Element;
  submenuItems?: Array<NavItem>;
  isLoading?: boolean;
  count?: number;
  countIsUrgent?: boolean;
};

export const useNavMenuItems = (): Array<NavItem> => {
  const workspaceURLSlug = useWorkspaceSlug();
  const { user } = useUserContext();
  const workspaceSlug = workspaceURLSlug || user?.workspaces?.[0]?._id || "";
  const { workspace } = useWorkspaceContext();
  const isMobile = useResponsiveMatch({ below: "sm" });

  const groupsRequest = useUserWorkspaceGroups();
  const groups = groupsRequest.state === "SUCCESS" ? groupsRequest.context : [];
  const navAgreementCount = useAgreementTypesCounts({
    workspaceId: workspaceSlug,
  });

  const groupMenuItems = groups
    .sort((a, b) => {
      if (a._id == workspace?.allMembersGroup._id) return -1;
      if (b._id == workspace?.allMembersGroup._id) return 1;
      return a.name.localeCompare(b.name, undefined, { sensitivity: "accent" });
    })
    .map((group) => {
      const label = getSimpleEditorTextOrLegacyText({
        nodeStr: group.name,
        defaultValue: "",
      });
      return {
        href: `/${workspaceSlug}/groups/${group._id}`,
        label,
      };
    });

  const isGuest = user.guestOfWorkspaces?.some(
    ({ _id }) => _id === workspaceSlug
  );
  const { query } = useRouter();
  const isStarling = user?.email?.endsWith("@murmur.com");
  const [murmurAdmin, setMurmurAdmin] = useSessionStorage(
    "murmurAdmin",
    !!process.env.NEXT_PUBLIC_FORCE_SHOW_ADMIN
  );
  useEffect(() => {
    if (
      isStarling &&
      (query.murmurAdmin === "on" || process.env.NEXT_PUBLIC_FORCE_SHOW_ADMIN)
    ) {
      setMurmurAdmin(true);
    } else {
      setMurmurAdmin(false);
    }
  }, [isStarling, query.murmurAdmin, setMurmurAdmin]);

  return [
    {
      href: `/${workspaceSlug}`,
      icon: <HomeIcon />,
      label: "Home",
    },
    ...(isMobile
      ? [
          {
            href: `/${workspaceSlug}/settings`,
            icon: <SettingsIcon />,
            label: "Workspace Settings",
          },
        ]
      : []),
    {
      href: `/explore`,
      icon: <CompassIcon />,
      label: "Explore",
    },
    {
      icon: user?._id ? (
        <UserAvatar user={{ _id: user._id, ...user }} size="tiny" />
      ) : (
        <PersonIcon />
      ),
      label: "My Murmur",
      submenuItems: [
        ...(!isGuest
          ? [
              {
                href: `/${workspaceSlug}/drafts`,
                label: "Drafts",
                count: navAgreementCount.draft,
              },
            ]
          : []),
        {
          href: `/${workspaceSlug}/proposals`,
          label: "Proposals",
          count: navAgreementCount.proposal,
        },
        {
          href: `/${workspaceSlug}/agreements`,
          label: "Agreements",
          count: navAgreementCount.active,
        },
      ],
    },
    ...(isGuest
      ? []
      : [
          {
            // TODO: link this to a page for all workspace proposals and agreements
            href: `/${workspaceSlug}/workspace`,
            icon: workspace?.logoFileId ? (
              <WorkspaceAvatar
                logoFileId={workspace.logoFileId}
                name={workspace.name}
                size="tiny"
              />
            ) : (
              <GroupsIcon />
            ),
            label: workspace?.name || "Workspace",
            submenuItems: groupMenuItems,
            isLoading: groupsRequest.state === "LOADING",
          },
        ]),
    ...(isStarling && murmurAdmin
      ? [
          {
            href: `/admin`,
            icon: <Emoji symbol="🐦" />,
            label: "Admin",
            submenuItems: [
              {
                href: `/${workspaceSlug}/templates`,
                label: "Templates",
                count: navAgreementCount.template,
              },
              {
                href: `/${workspaceSlug}/collections`,
                label: "Collections",
                count: navAgreementCount.collection || 0,
              },
              {
                href: `/admin/prompts`,
                label: "Prompy",
              },
              {
                href: `/${workspaceSlug}/creator-profiles`,
                label: "Creator Profiles",
              },
            ],
            isLoading: groupsRequest.state === "LOADING",
          },
        ]
      : []),
  ];
};
