import type { FC } from "react";
import React from "react";

import TeamUndefined from "~/components/TeamUndefined";
import { WorkspaceProvider } from "~/contexts/WorkspaceContext";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

import { MainWrap } from "./styles";

const FocusLayout: FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  const workspaceSlug = useWorkspaceSlug();

  // TODO
  // I'm 100% confident this is not the right way to handle this.
  // If I'm logged in looking at some other team's public agreements or public
  // profile, their workspaceSlug would end up in my acccount URL.
  // I think the better way to do this is to set the user's current workspace
  // globally and use that in both FocusLayout and in MainLayout.
  if (!workspaceSlug || workspaceSlug === "Undefined") {
    return (
      <MainWrap>
        <TeamUndefined />
      </MainWrap>
    );
  }

  /**
   * We have several variations of what takes place within the FocusLayout
   * regarding what parts are sticky, what needs data where. For now, it's
   * simplest to compose those parts on a per page basis. To this end,
   * components used solely by FocusLayout for layout will be colocated in this
   * directory, such as `StickyTop` and `FocusHeader`, so that they can be
   * quickly grabbed and used in compositions accommodating our needs.
   */
  return (
    <WorkspaceProvider slug={workspaceSlug}>
      <div css={{ minHeight: "100vh" }} className={className}>
        {children}
      </div>
    </WorkspaceProvider>
  );
};

export default FocusLayout;
