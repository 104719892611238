import { useQuery } from "@apollo/client";

import { GET_AGREEMENT_TYPES_COUNTS } from "~/components/Sidebar/graphql/query";
import type { AgreementsParams } from "~/graphql/generated";
import { useUserInWorkspace } from "~/hooks/useUserInWorkspace";

export const useAgreementTypesCounts = (agreementsParams: AgreementsParams) => {
  const [userInWorkspace] = useUserInWorkspace({
    workspaceId: agreementsParams.workspaceId,
  });

  const result = useQuery(GET_AGREEMENT_TYPES_COUNTS, {
    variables: {
      agreementsParams,
    },
    fetchPolicy: "cache-and-network",
    skip: !userInWorkspace,
  });

  if (!result.data) {
    return {
      active: 0,
      draft: 0,
      proposal: 0,
      template: 0,
      collection: 0,
    };
  }

  return {
    active: result.data.activeAgreementsCount.needsAction,
    draft: result.data.draftsCount.total,
    proposal: result.data.proposalsCount.needsAction,
    template: result.data.templatesCount.total,
    collection: result.data.allCollectionsCount.total,
  };
};
