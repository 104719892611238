import { FC, ReactNode, ReactNodeArray } from "react";

import { useResponsiveMatch } from "~/hooks/useResponsiveMatch";
import { Breakpoint } from "~/styles/global";

/**
 * ResponsiveHide conditionally renders a component depending
 * on the viewport width.
 */
export const ResponsiveHide: FC<{
  children: ReactNode | ReactNodeArray;
  above?: Breakpoint;
  below?: Breakpoint;
}> = ({ children, above, below }) => {
  const match = useResponsiveMatch({ above, below });
  if (match) {
    return null;
  }
  return <>{children}</>;
};
