import { useEffect, useState } from "react";

import { useUserContext } from "~/contexts/UserContext";

export default function useBestRootPath({
  workspaceSlug = "",
  appendPath = "",
}: {
  workspaceSlug?: string;
  appendPath?: string;
}) {
  const { user } = useUserContext();

  const [rootPath, setRootPath] = useState("/");

  useEffect(() => {
    // make the link for the Murmur icon take the user to the right place depending
    // on the following variations:
    // - the user isn't logged in
    // - the user is logged in but viewing an agreement or template outside of their
    //    workspace
    // - the user is logged in and is in one of their workspaces
    if (user?.workspaces?.length || user?.guestOfWorkspaces?.length) {
      if (
        user.workspaces?.some((workspace) => workspace._id === workspaceSlug) ||
        user.guestOfWorkspaces?.some(
          (workspace) => workspace._id === workspaceSlug
        )
      ) {
        setRootPath(`/${workspaceSlug}${appendPath}`);
      } else {
        setRootPath(
          `/${
            (
              user.workspaces?.[0] ||
              user.guestOfWorkspaces?.[0] || { _id: "login" }
            )._id
          }${appendPath}`
        );
      }
    } else {
      setRootPath(`/login`);
    }
  }, [user, workspaceSlug, appendPath]);

  return rootPath;
}
