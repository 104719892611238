import styled, { css } from "styled-components";

import DefaultArchiveIcon from "~/assets/icons/archive.svg";
import DefaultClipboardIcon from "~/assets/icons/clipboard.svg";
import DefaultCompassIcon from "~/assets/icons/compass.svg";
import DefaultDeathIcon from "~/assets/icons/death.svg";
import DefaultDiscardsIcon from "~/assets/icons/discards.svg";
import DefaultDraftIcon from "~/assets/icons/draft.svg";
import DefaultEditIcon from "~/assets/icons/edit.svg";
import DefaultCollectionsIcon from "~/assets/icons/formatBlockquote.svg";
import DefaultTemplatesIcon from "~/assets/icons/formatCode.svg";
import DefaultGaugeIcon from "~/assets/icons/gauge.svg";
import DefaultGroupsIcon from "~/assets/icons/groups.svg";
import DefaultHomeIcon from "~/assets/icons/home.svg";
import DefaultPersonIcon from "~/assets/icons/person.svg";
import DefaultSettingsIcon from "~/assets/icons/settings.svg";
import DefaultLogo from "~/components/Logo";
import { SidebarWidthRem } from "~/components/Sidebar/constants";

export const Container = styled.div<{ $open: boolean }>`
  background-color: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.moon};
  padding: 1rem;
  padding-bottom: 2rem;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.1s ease-in-out, width 0.1s ease-in-out;
  transform: translateX(${({ $open }) => ($open ? 0 : -SidebarWidthRem)}rem);
  width: ${({ $open }) => ($open ? SidebarWidthRem : 0)}rem;
`;

export const LogoContainer = styled.div`
  margin-left: 1rem;
`;

export const Logo = styled(DefaultLogo)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Content = styled.div`
  width: 14.25rem;
`;

export const Heading = styled.h1`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-left: 0.25rem;
  font-size: 1.15rem;
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const SettingsLink = styled.a`
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.speech25};
    & svg {
      animation: spin 2s;
    }
  }
`;

export const Menu = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 1.5rem;
`;

const icon = css`
  height: 16px;
  width: 16px;
`;

export const ArchiveIcon = styled(DefaultArchiveIcon)`
  ${icon};
`;

export const ClipboardIcon = styled(DefaultClipboardIcon)`
  ${icon};
`;

export const CompassIcon = styled(DefaultCompassIcon)`
  ${icon};
`;

export const DeathIcon = styled(DefaultDeathIcon)`
  ${icon};
`;

export const DiscardsIcon = styled(DefaultDiscardsIcon)`
  ${icon};
`;

export const DraftIcon = styled(DefaultDraftIcon)`
  ${icon};
`;

export const EditIcon = styled(DefaultEditIcon)`
  ${icon};
`;

export const GaugeIcon = styled(DefaultGaugeIcon)`
  ${icon};
`;

export const HomeIcon = styled(DefaultHomeIcon)`
  ${icon};
`;

export const PersonIcon = styled(DefaultPersonIcon)`
  ${icon};
`;

export const SettingsIcon = styled(DefaultSettingsIcon)`
  ${icon};
`;

export const GroupsIcon = styled(DefaultGroupsIcon)`
  ${icon};
`;

export const TemplatesIcon = styled(DefaultTemplatesIcon)`
  ${icon};
`;

export const CollectionsIcon = styled(DefaultCollectionsIcon)`
  ${icon};
`;
