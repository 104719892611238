import { gql } from "@apollo/client";

import { GROUP_FRAGMENT } from "~/graphql/fragments/group";

export const GET_USER_WORKSPACE_GROUPS = gql`
  query GetUserWorkspaceGroups($params: WorkspaceGroupsParams!) {
    userWorkspaceGroups(params: $params) {
      ...Group
    }
  }

  ${GROUP_FRAGMENT}
`;
