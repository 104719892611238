import styled from "styled-components";

import DefaultCloudCheckIcon from "~/assets/icons/cloud-check.svg";
import DefaultCloudSyncIcon from "~/assets/icons/cloud-upload.svg";
import DefaultLogo from "~/components/Logo";
import { resetListStyles } from "~/styles/resets";

export const Container = styled.header`
  background-color: ${({ theme }) => theme.colors.moonLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
`;

export const DraftHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Breadcrumbs = styled.nav`
  height: fit-content;
  margin-left: 2rem;
`;

export const BreadcrumbsList = styled.ol`
  ${resetListStyles};
  display: flex;
`;

export const Breadcrumb = styled.li`
  color: ${({ theme }) => theme.colors.oil};
  font-size: 0.875rem;
  line-height: 1;
  list-style-type: none;

  :not(:last-child)::after {
    content: "/";
    margin: 0 0.375rem;
  }
`;

export const BreadcrumbLink = styled.a`
  color: ${({ theme }) => theme.colors.oil};
  text-decoration: none;
`;

export const CloudSyncIcon = styled(DefaultCloudSyncIcon)`
  color: ${({ theme }) => theme.colors.moonDark};
  width: 20px;
  height: 20px;
`;

export const CloudCheckIcon = styled(DefaultCloudCheckIcon)`
  color: ${({ theme }) => theme.colors.moonDark};
  width: 20px;
  height: 20px;
`;

export const AutoSaveIcon = styled.div`
  margin-top: 5px;
  margin-left: 0.6rem;
  width: 20px;
  height: 20px;
`;

export const Logo = styled(DefaultLogo)`
  color: ${({ theme }) => theme.colors.oil};
`;
