import { AppBar, IconButton } from "@mui/material";
import Link from "next/link";
import { ComponentType, FC, useCallback, useState } from "react";
import { animated, useSpring } from "react-spring";
import styled, { useTheme } from "styled-components";

import MenuIcon from "~/assets/icons/hamburger-menu-icon.svg";
import { useUserContext } from "~/contexts/UserContext";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";

import { Column } from "../Column";
import Header from "../Header";
import { Row } from "../Row";
import { MenuContent } from "../Sidebar";
import { Logo } from "../Sidebar/styles";

const AnimatedDiv = styled(animated.div)``;

export const CollapsibleHeader: FC<{ actionButton?: ComponentType }> = () => {
  const { workspace } = useWorkspaceContext();
  const { authenticated } = useUserContext();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setExpanded((val) => !val);
  }, [setExpanded]);

  const styles = useSpring({
    from: { height: 0 },
    to: { height: expanded ? 440 : 0 },
    config: {
      delay: 0,
      mass: 0.3,
      friction: 13,
      tension: 500,
      clamp: !expanded,
    },
  });

  const theme = useTheme();

  return (
    <AppBar
      css={`
        width: 100vw;
        min-height: 4rem;
        padding-bottom: 0;
        background-color: ${theme.colors.navy} !important;
        z-index: 3000 !important; // required to get above StickyBottom

        /*
          Why not just put these styles on the AnimatedDiv itself? 
          
          Because if you combine react-spring animated.div with 
          the styled-components css prop then TypeScript says:
            > Type instantiation is excessively deep and possibly infinite.
            > ts(2589)

          This issue is tracked at https://github.com/pmndrs/react-spring/issues/1515
         */
        .a {
          overflow: ${expanded ? "scroll" : "hidden"};
        }
      `}
    >
      <Column>
        <Row
          css={`
            justify-content: space-between;
            padding: 0 1rem;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              align-content: center;
              height: 4rem;
            `}
          >
            <Link href={`/${workspace?._id}`}>
              {/* Added a dummy href to the <a> below to satisfy a11y linter (03/21).
          Soon, this won't be necessary anymore because Next is working on supporting a Link that doesn't require an <a> child.
          More info: https://github.com/vercel/next.js/discussions/8207
          */}
              <a
                href={`/${workspace?._id}`}
                css={`
                  height: 2.25rem;
                  margin-right: 1rem;
                `}
              >
                <Logo />
              </a>
            </Link>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleExpanded}
            >
              {authenticated && (
                <MenuIcon
                  css={`
                    height: 1.5rem;
                    width: 1.5rem;
                  `}
                />
              )}
            </IconButton>
          </div>
          <Header workspaceSlug={workspace?._id || ""} />
        </Row>

        {authenticated && (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <AnimatedDiv className="a" style={styles} onClick={toggleExpanded}>
              <Column>
                <Row
                  css={`
                    justify-content: space-between;
                    gap: 1rem;
                  `}
                >
                  {/* TODO: Make these look nice on mobile
                  {shouldDisplayInviteLink && <AddMembersModalWithWorkspace />}
                  <ActionButton /> 
                */}
                </Row>
                <MenuContent />
              </Column>
            </AnimatedDiv>
          </>
        )}
      </Column>
    </AppBar>
  );
};
